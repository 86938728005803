import { useState, useEffect } from "react";
import { HeroBlock } from "../models";
import { getHeroBlockInfo } from "../services/api";

export const HeroBlockComponent = () => {
  const [heroBlockInfo, setHeroBlockInfo] = useState({
    Description: "",
    Headline: "",
    ImageUrl: "",
  } as HeroBlock);

  useEffect(() => {
    const getHeroBlockInfoFromApi = async () => {
      const info = (await getHeroBlockInfo()) as HeroBlock;
      setHeroBlockInfo(info);
    };
    getHeroBlockInfoFromApi();
  }, []);

  return (
    <>
      {heroBlockInfo && (
        <div className="layout_padding banner_section">
          <div className="container">
            <div
              id="main_slider"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-6 taital">
                      <h1 className="taital banner_taital" id="heroHeadline">
                        {heroBlockInfo.Headline}
                      </h1>
                      <p className="lorem_text" id="heroDescription"></p>
                    </div>
                    <div className="col-md-6">
                      <div className="banner-image">
                        <img
                          alt=""
                          id="heroImage"
                          src={heroBlockInfo.ImageUrl}
                          style={{ maxWidth: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="banner_bt"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
