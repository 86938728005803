import React from "react";
import { Product } from "../models";
export interface IProductProps {
  product: Product;
}

export const ProductComponent: React.FC<IProductProps> = (
  props: IProductProps
) => {
  return (
    <>
      {props.product.Name && (
        <div className="col-sm-4">
          {" "}
          <div className="images">
            <img
              alt={props.product.Name}
              title={props.product.Name}
              src={props.product.ImageUrl}
              style={{ maxWidth: "100%", width: "100%" }}
            />
          </div>{" "}
          <div className="den_text">
            <h3>{props.product.Name}</h3>
          </div>
        </div>
      )}
    </>
  );
};
