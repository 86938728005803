import { useState, useEffect } from "react";
import { ContactForm, OrderEntry } from "../models";
import { useForm, useFormState } from "react-hook-form";
import { getContactFormInfo, sendOrder } from "../services/api";
import ReCAPTCHA from 'react-google-recaptcha';

export const ContactFormComponent = () => {
  const { register, setValue, handleSubmit, resetField, formState: { errors } } = useForm<OrderEntry>();
  const [contactFormInfo, setContactFormInfo] = useState({} as ContactForm);
  const [orderSendResult, setOrderSendResult] = useState(false);
  const [sending, setSending] = useState(false);
  let isHuman = false;

  const recaptcha_callback = (value: string) => {
    let orderBtn = document.getElementById("orderBtn");
    isHuman = true;
  }

  const onSubmit = async (data: OrderEntry, e: any) => {
    if (isHuman) {
      setSending(true);
      const result = await sendOrder(data);
      setOrderSendResult(result.status === 200);

      if (result.status === 200) {
        setSending(false);
        setTimeout(async () => {
          setOrderSendResult(false);
          resetField("Name");
          resetField("Email");
          resetField("PhoneNr");
          resetField("Message");
        }, 3000);
      }
    }
  }

  useEffect(() => {
    const getContactFormInfoFromApi = async () => {
      const contactFormInfoFromApi =
        (await getContactFormInfo()) as ContactForm;
      setContactFormInfo(contactFormInfoFromApi);
    };
    getContactFormInfoFromApi();
  }, []);

  return (
    <>
      {contactFormInfo && (
        <div
          className="layout_padding gallery_section d-block"
          id="order_section"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="gallery_main">
                  <h1 className="gallery_taital">
                    <strong>
                      <span className="our_text">{contactFormInfo.Title}</span>
                    </strong>
                  </h1>
                </div>
              </div>
            </div>
            <div className="touch_main">
              <div className="row">
                <div className="col-md-12">
                  <div className="contact1">
                    <div className="container-contact1">
                      <div className="contact1-pic js-tilt" data-tilt>
                        {orderSendResult &&
                          <div
                            id="successMessage"
                            style={{ color: "black" }}
                          >{contactFormInfo.ThanksMessage}</div>
                        }
                        <img alt="" src={contactFormInfo.ImageUrl} />
                        {sending &&
                          <div id="orderLoading">
                            <img
                              src="images/orderloading.gif"
                              alt={contactFormInfo.SendingOrder}
                              title={contactFormInfo.SendingOrder}
                            />{" "}
                            {contactFormInfo.SendingOrder}
                            <br />
                            {contactFormInfo.BeKindWait}
                          </div>
                        }
                      </div>

                      <form onSubmit={handleSubmit(onSubmit)}
                        className="contact1-form validate-form"
                        name="orderForm"
                        id="orderForm"
                      >
                        <div
                          className="wrap-input1 validate-input"
                          data-validate={contactFormInfo.NameValidationMessage}
                        >
                          <input
                            className="input1"
                            type="text"
                            id="name"
                            placeholder={contactFormInfo.NameFieldPlaceholder}
                            {...register("Name", {
                              required: true,
                              maxLength: 55,
                            })}
                          />

                          {errors.Name && (
                            <p className="alert alert-danger" role="alert">
                              {contactFormInfo.NameValidationMessage}
                            </p>
                          )}
                          <span className="shadow-input1"></span>
                        </div>

                        <div
                          className="wrap-input1 validate-input"
                          data-validate={contactFormInfo.EmailValidationMessage}
                        >
                          <input
                            className="input1"
                            type="text"
                            id="email"
                            placeholder={contactFormInfo.EmailFieldPlaceholder}
                            {...register("Email", {
                              required: true,
                              maxLength: 100,
                            })}
                          />
                          {errors.Email && (
                            <p className="alert alert-danger" role="alert">
                              {contactFormInfo.EmailValidationMessage}
                            </p>
                          )}
                          <span className="shadow-input1"></span>
                        </div>

                        <div
                          className="wrap-input1 validate-input"
                          data-validate={
                            contactFormInfo.PhoneNrValidationMessage
                          }
                        >
                          <input
                            className="input1"
                            type="text"
                            id="phoneNr"
                            placeholder={contactFormInfo.PhoneFieldPlaceholder}
                            {...register("PhoneNr", {
                              required: true,
                              maxLength: 55,
                            })}
                          />
                          {errors.PhoneNr && (
                            <p className="alert alert-danger" role="alert">
                              {contactFormInfo.PhoneNrValidationMessage}
                            </p>
                          )}
                          <span className="shadow-input1"></span>
                        </div>

                        <div
                          className="wrap-input1 validate-input"
                          data-validate={
                            contactFormInfo.MessageValidationMessage
                          }
                        >
                          <textarea
                            className="input1"
                            id="message"
                            placeholder={
                              contactFormInfo.MessageFieldPlaceholder
                            }
                            {...register("Message", {
                              required: true,
                              maxLength: 500,
                            })}
                          ></textarea>
                          {errors.Message && (
                            <p className="alert alert-danger" role="alert">
                              {contactFormInfo.MessageValidationMessage}
                            </p>
                          )}
                          <span className="shadow-input1"></span>
                        </div>
                        <div className="wrap-input1">
                          <ReCAPTCHA
                            sitekey="6Le8lvodAAAAALl0khTOhd-WaWgAzZfttBLiHCLY"
                            onChange={value => recaptcha_callback(value!)} />
                        </div>

                        <div className="container-contact1-form-btn">
                          <button className="contact1-form-btn" id="orderBtn"
                            onClick={() => {
                            }}>
                            <span>
                              {contactFormInfo.SendButtonText}{" "}
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
