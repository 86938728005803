import React, { useState, useEffect, useContext } from "react";
import { Contact } from "../models";
import { getContactInfo } from "../services/api";

export const ContactComponent = () => {
  const [contactInfo, setContactInfo] = useState({
    Description: "",
    Name: "",
    PhoneNr: "",
    Address: "",
    Email: "",
    InstagramLink: "",
    FacebookLink: "",
    TikTokLink: "",
    InstagramImageUrl: "",
    FacebookImageUrl: "",
    TikTokImageUrl: "",
  } as Contact);

  useEffect(() => {
    const getContactInfoFromApi = async () => {
      const contactInfoFromApi = (await getContactInfo()) as Contact;
      setContactInfo(contactInfoFromApi);
    };
    getContactInfoFromApi();
  }, []);

  return (
    <>
      {contactInfo && (
        <>
          <div id="contact" className="contact_section d-block">
            <div className="container">
              <div className="contact-taital">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="contact_text" id="contactHeadline">
                      <strong>{contactInfo.Name}</strong>
                    </h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="image-icon">
                      <img alt="" src="images/map-icon.png" />
                      <span id="address" className="address_text">
                        {contactInfo.Address}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="image-icon">
                      <img alt="" src="images/phone-icon.png" />
                      <span id="phoneNr" className="phonenr_text">
                        {contactInfo.PhoneNr}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="image-icon">
                      <img alt="" src="images/email-icon.png" />
                      <a href="">
                        <span id="email" className=" email_text">
                          {contactInfo.Email}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright_section d-block" id="copyright">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <p className="copyright_taital">
                    2019 All Rights Reserved.
                    <a style={{ color: "#fff" }} href="https://html.design">
                      Free html Templates
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
