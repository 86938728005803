import React, { useState, useEffect, useContext } from "react";
import { AboutUs } from "../models";
import { getAboutInfo } from "../services/api";

export const AboutComponent = () => {
  const [aboutInfo, setAboutInfo] = useState({
    Description: "",
    Name: "",
    ImageUrl: "",
  } as AboutUs);

  useEffect(() => {
    const getAboutInfoFromApi = async () => {
      const info = await getAboutInfo();
      setAboutInfo(info);
    };
    getAboutInfoFromApi();
  }, []);

  return (
    <>
      {aboutInfo && (
        <div id="about" className="layout_padding about_section d-block">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <img
                    id="aboutImage"
                    alt=""
                    src={aboutInfo.ImageUrl}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h1 className="about_text color" id="aboutHeadline">
                  {aboutInfo.Name}
                </h1>
                <p
                  className="about_taital"
                  id="aboutDescription"
                  dangerouslySetInnerHTML={{ __html: aboutInfo.Description }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
