import React, { useEffect, useState } from "react";
import "./App.css";
import { HeaderComponent } from "./components/HeaderComponent";
import { AboutComponent } from "./components/AboutComponent";
import { IAppContext, GuiText } from "./models";
import { getGuiTexts } from "./services/api";
import { AppContextProvider } from "./AppContext";
import { ProductsComponent } from "./components/ProductsComponent";
import { GalleryItemsComponent } from "./components/GalleryItemsComponent";
import { ContactFormComponent } from "./components/ContactFormComponent";
import { MapComponent } from "./components/MapComponent";
import { ContactComponent } from "./components/ContactComponent";

export const App = () => {
  const [guiTexts, setGuiTexts] = useState(Array<GuiText>());

  const callMatomo = async () => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    if (s.parentNode) {
      g.async = true; g.src = 'https://analytics.enginmarshall.se/js/container_S5TKvzLG.js'; s.parentNode.insertBefore(g, s);
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      callMatomo();
      return () => clearTimeout(timeOut);
    }, 2000);
  }, []);

  useEffect(() => {
    const getGuiTextsFromApi = async () => {
      const guiTextsFromApi = await getGuiTexts();
      setGuiTexts(guiTextsFromApi);
    };

    getGuiTextsFromApi();
  }, []);

  const appContext: IAppContext = {
    guiTexts: guiTexts,
  };
  if (!guiTexts || guiTexts && guiTexts.length === 0) {
    return (
      <div className="text-center loader" id="loading">
      </div>
    );
  }

  return (
    <div className="App" id="rootContent">
      <AppContextProvider value={appContext}>
        <HeaderComponent />
        <AboutComponent />
        <ProductsComponent />
        <GalleryItemsComponent />
        <ContactFormComponent />
        <MapComponent />
        <ContactComponent />
      </AppContextProvider>
    </div>
  );
};
