import {
  ContactForm,
  AboutUs,
  Contact,
  GalleryItem,
  GuiText,
  HeroBlock,
  Product,
  GoogleMap,
  OrderEntry
} from "../models";

let guiTexts = Array<GuiText>();
let aboutUsInfo = Array<AboutUs>();
let heroBlockInfo: HeroBlock;
let products = Array<Product>();
let galleryItems = Array<GalleryItem>();
let map = {} as GoogleMap;
let contact = {} as Contact;
let contactForm = {} as ContactForm;

// const baseUrl = "http://localhost:9595/api/MyCake/";
const baseUrl = "https://apicenter.enginmarshall.se/api/MyCake/";

export const getGuiTexts = async () => {
  let response = await fetch(baseUrl + "guitexts");
  let data = await response.json();
  guiTexts = [...data] as Array<GuiText>;
  //  console.log(guiTexts);
  return guiTexts;
};

export const getGuiText = function (dictionary: GuiText[], key: string) {
  let returnValue = "-";
  if (dictionary && dictionary.length > 0) {
    const obj = dictionary.find((x) => x.Id === key);
    if (obj && obj.hasOwnProperty("Value")) {
      returnValue = obj.Value;
    }
  }
  return returnValue;
};

export const getHeroBlockInfo = async () => {
  let response = await fetch(baseUrl + "heroblock");
  let data = await response.json();
  heroBlockInfo = data[0] as HeroBlock;
  //  console.log(heroBlockInfo);
  return heroBlockInfo;
};

export const getAboutInfo = async () => {
  let response = await fetch(baseUrl + "about");
  let data = await response.json();
  aboutUsInfo = [...data] as Array<AboutUs>;
  return aboutUsInfo[0];
};

export const getProducts = async () => {
  let response = await fetch(baseUrl + "products");
  let data = await response.json();
  // console.log(data);
  const tempProducts = [...data] as Array<Product>;
  //  products = [...data];
  // console.log(tempProducts);
  tempProducts.map((product, index) => {
    const newProduct = product;
    products.push(newProduct);
    return newProduct;
  });
  // console.log(products);
  return products;
};

export const getGalleryItems = async () => {
  let response = await fetch(baseUrl + "galleryitems");
  let data = await response.json();
  // console.log(data);
  const tempItems = [...data] as Array<GalleryItem>;
  // console.log(tempItems);
  tempItems.map((item, index) => {
    const newItem = item;
    galleryItems.push(newItem);
    return newItem;
  });
  // console.log(galleryItems);
  return galleryItems;
};

export const getMapInfo = async () => {
  let response = await fetch(baseUrl + "map");
  let data = await response.json();
  map = data as GoogleMap;
  return map;
};

export const getContactInfo = async () => {
  let response = await fetch(baseUrl + "contact");
  let data = await response.json();
  contact = data as Contact;
  // console.log(contact);
  return contact;
};

export const getContactFormInfo = async () => {
  let response = await fetch(baseUrl + "contactForm");
  let data = await response.json();
  contactForm = data as ContactForm;
  // console.log(contactForm);
  return contactForm;
};

export const sendOrder = async (orderEntry: OrderEntry): Promise<Response> => {
  const data = { name: orderEntry.Name, email: orderEntry.Email, phoneNr: orderEntry.PhoneNr, message: orderEntry.Message };
  console.log(data);
  const url = baseUrl + "createneworderentry";
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  }
  const opts: RequestInit = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };
  let returnValue = fetch(url, opts);
  console.log(returnValue);

  return returnValue;
};
