import { useState, useEffect } from "react";
import { GoogleMap } from "../models";
import { getMapInfo } from "../services/api";

export const MapComponent = () => {
  const [mapInfo, setMapInfo] = useState({
    Title: "",
    Url: "",
  } as GoogleMap);

  useEffect(() => {
    const getMapInfoFromApi = async () => {
      const mapInfoFromApi = (await getMapInfo()) as GoogleMap;
      setMapInfo(mapInfoFromApi);
    };
    getMapInfoFromApi();
  }, []);

  return (
    <>
      {mapInfo && (
        <div className="map_section d-block" id="map_section">
          <div id="map">
            <iframe
              id="mapFrame"
              title={mapInfo.Title}
              src={mapInfo.Url}
              width="100%"
              height="480"
              style={{ border: 0 }}
              loading="lazy"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};
