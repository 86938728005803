import { useState, useEffect } from "react";
import { Product } from "../models";
import { getProducts } from "../services/api";
import { ProductComponent } from "./ProductComponent";

export const ProductsComponent = () => {
  const [products, setProducts] = useState(Array<Product>());

  useEffect(() => {
    const getProductsFromApi = async () => {
      const prods = await getProducts();
      setProducts(prods);
    };
    getProductsFromApi();
  }, [products]);

  const productList = products.map((product, index) => {
    return <ProductComponent key={index} product={product} />;
  });

  return (
    <>
      {products && products.length > 0 && (
        <div id="products" className="layout_padding product_section d-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="product_text product-header" id="productHeader">
                  Produkter
                </h1>
              </div>
            </div>
            <div className="product_section_2 images">
              <div className="row" id="productRow">
                {productList}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
