import { HeroBlockComponent } from "./HeroBlockComponent";

export const HeaderComponent = () => {
  return (
    <>
      <header id="header" className="d-block">
        <div className="layout_padding banner_section_start">
          <div className="header">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col logo_section">
                  <div className="full">
                    <div className="center-desk">
                      <div className="logo">
                        <a href="#home">
                          <h1>MyCake</h1>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                  <div className="menu-area">
                    <div className="limit-box">
                      <nav className="main-menu">
                        <ul className="menu-area-main">
                          <li className="active">
                            <a href="#home">Hem</a>
                          </li>
                          <li>
                            <a href="#about">Om oss</a>
                          </li>
                          <li>
                            <a href="#products">Produkter</a>
                          </li>
                          <li>
                            <a href="#contact">Kontakta oss</a>
                          </li>
                          <li>
                            <a href="#galleryItemHeader">Bilder</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HeroBlockComponent />
        </div>
      </header>
    </>
  );
};
