import React, { useState, useEffect } from "react";
import { GalleryItem } from "../models";
import { getGalleryItems } from "../services/api";

export const GalleryItemsComponent = () => {
  const [galleryItems, setgalleryItems] = useState(Array<GalleryItem>());

  useEffect(() => {
    const getgalleryItemsFromApi = async () => {
      const items = await getGalleryItems();
      setgalleryItems(items);
    };
    getgalleryItemsFromApi();
  }, [galleryItems]);

  const galleryItemList = galleryItems.map((galleryItem, index) => {
    return (
      <div key={index} className="col-sm-4">
        {" "}
        <div className="images">
          <img
            alt={galleryItem.Name}
            title={galleryItem.Name}
            src={galleryItem.ImageUrl}
            style={{ maxWidth: "100%", width: "100%" }}
          />
        </div>{" "}
        <div className="den_text">
          <h3>{galleryItem.Name}</h3>
        </div>
      </div>
    );
  });

  return (
    <div id="gallery">
      {galleryItems && galleryItems.length > 0 && (
        <div className="layout_padding galleryItem_section d-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1
                  className="galleryItem_text galleryItem-header"
                  id="galleryItemHeader"
                >
                  Galleri
                </h1>
              </div>
            </div>
            <div className="galleryItem_section_2 images">
              <div className="row" id="galleryItemRow">
                {galleryItemList}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
